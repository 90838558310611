import Turbolinks from 'turbolinks'
Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  const navToggle = document.querySelector('.nav-toggle')
  const navigation = document.querySelector('.nav-primary')

  navToggle.addEventListener('click', function () {
    if (navigation.classList.contains('active')) {
      document.documentElement.removeAttribute('style')
      navigation.classList.remove('active')
    } else {
      document.documentElement.style.overflow = 'hidden'
      navigation.classList.add('active')
    }
  })
})

document.addEventListener('turbolinks:visit', () => {
  if (document.documentElement.hasAttribute('style')) {
    document.documentElement.removeAttribute('style')
  }
})
